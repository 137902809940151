/* :default css */
/* User Add */
.banner {
    box-shadow: 0px 4px 6px rgba(49, 49, 49, 0.3);
    padding: 20px 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-button {
    background-color: #2F6F92;
    color: white;
    border: none;
    /* padding: 5px 20px; */
    border-radius: 4px;
    cursor: pointer;
    width: 10%;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    /* margin: 0 auto */
}
.login-button-1 {
    background-color: #2F6F92;
    color: white;
    border: none;
    /* padding: 5px 20px; */
    border-radius: 4px;
    cursor: pointer;
    width: 10%;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    /* margin: 0 auto */
}
form {
    width: 100%;
}

::placeholder {
    color: white;
    font-size: 16px;
}

::placeholder {
    color: rgb(231, 225, 225) !important;
    font-weight: 200;
}

.label {
    font-weight: 500;
}



/* In your CSS file or a <style> block */
.white-placeholder::placeholder {
    color: white;
    opacity: 1;
    z-index: +99;
}

.select-container {
    font-size: 14px;
    text-align: left;
}

.drop-down {
    font-size: 17px;
    color: #0B2C5F;
}

/* For screens from 4000px down to 1600px */
@media screen and (max-width: 4000px) and (min-width: 3000px) {
    .login-img {
        width: 150%;
    }

    .login-page label {
        font-size: 50px;
        margin-bottom: 30px;
    }

    .login-page ::placeholder {
        font-size: 45px;
        z-index: +999;
    }

    .login-page input {
        font-size: 50px;
        border-bottom: 50px solid white;
    }

    .btn-password {
        font-size: 36px;
    }

    header {
        padding: 40px;
    }

    .login-page .form-check-label {
        font-size: 45px;
    }

    .login-page .forgot-pwd {
        font-size: 45px;
    }

    .login-page .fields {
        margin-bottom: 60px;
    }

    .login-page .login-btn {
        font-size: 45px;
        margin-top: 20px;
        padding: 25px 160px;
    }

    .drop-down {
        font-size: 50px;
        color: #0B2C5F;
        margin: 20px;
    }

    /* add */
    .banner {
        padding: 50px 50px;
    }

    .banner form label {
        font-size: 36px;
        margin-bottom: 10px;
    }

    .three-opt {
        display: flex;
        justify-content: space-between;
    }


    form .row {
        margin-bottom: 50px;
    }

    .login-container input {
        font-size: 44px;
    }

    .login-button {
        width: 10%;
        padding-top: 15px;
        padding-bottom: 12px;
        font-weight: 400;
        font-size: 34px;
        border: none;
        /* padding: 5px 20px; */
        cursor: pointer;
        border-radius: 10px;
    }   
    .login-button-1 {
        width: 10%;
        padding-top: 15px;
        padding-bottom: 12px;
        font-weight: 400;
        font-size: 34px;
        border: none;
        /* padding: 5px 20px; */
        cursor: pointer;
        border-radius: 10px;
    }

    .dash-pad {
        padding: 30px 0px 0px 30px;
    }

    .select-container .react-select__control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #0B2C5F;
        border-radius: 0;
        box-shadow: none;
        color: #000000;
        height: 40px;
        min-width: 200px;
        font-size: 50px;
        width: 100%;
    }

    .select-container {
        font-size: 50px;
        text-align: left;
    }



    .select-container .react-select__menu {
        text-align: left;
        width: 100%;
        font-size: 50px;
    }

    .select-container .react-select__single-value {
        text-align: left;
        font-size: 50px;
    }

    .fa-circle-user:before {
        font-size: 1.7vw;
    }

    .dropdown-toggle::after {
        font-size: 1vw;
    }

    .save-btn {
        height: 2.5vw;
        width: 6.5vw;
        font-size: 1vw;
    }


    .edit-btn {
        font-size: 1vw;

    }

    .trash-btn {
        font-size: 1vw;
    }

    .column {
        font-size: 14px;
    }

    .column td {
        font-size: 1.1vw;
    }

    .column th {
        font-size: 1.18vw;
    }

    .popup-list li {
        padding: 10px;
        margin-bottom: 10px;
    }

    .reg_customer {
            padding-right: 9px;
            margin-left: 20px;
            height: 2.6vw;
            width: 11vw;
            color: white;
            font-size: 1.09vw;
            font-weight: 500;
            border-radius: 9px;
            border: 1px solid #2F6F92;
            /* font-family: Geometria; */
            background: #2F6F92;
            box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.3);
    }


    .search-main::placeholder {
        font-size: 1.01vw;
        border-radius: 9px;
        /* Adjust the font size as needed */
    }

    .search-main {
        width: 20vw;
        border-radius: 15px;
        border: 3px solid rgb(134, 172, 182);

    }


    .i {
        font-size: 40px;
    }



    .btn.active {
        font-size: 27px;
        height: 2.2vw;
        width: 2.2vw;
    }



    .pg-drp {
        padding: 5px 10px; /* Add padding for better touch experience */
        font-size: 14px; /* Ensure consistent font size */
        border: 1px solid #ccc; /* Subtle border */
        border-radius: 4px; /* Rounded corners */
        background-color: #fff; /* White background */
        cursor: pointer;
        outline: none;
      }
    /* 
    button:not(:disabled) {
        font-size: 35px;
        cursor: pointer;
    } */

    .pagi-dropdown {
        width: 3.5%;
        font-size: 40px;
    }
    .pagi-dropdown {
        width: 3.5%;
        font-size: 40px;
    }

    .pagi-dropdown option {
        font-size: 10px;
        padding: 0 !important;
        line-height: 0px;
    }
    .pagi-dropdown option {
        font-size: 10px;
        padding: 0 !important;
        line-height: 0px;
    }

    .items-per-page-dropdown option {
        font-size: 10px;
    }
    .items-per-page-dropdown option {
        font-size: 10px;
    }

    .edit-button {
        background-color: #2F6F92;
        padding: 25px 30px;
        border-radius: 20px;
        color: white;
        border: 0;
    }
    .edit-button {
        background-color: #2F6F92;
        padding: 25px 30px;
        border-radius: 20px;
        color: white;
        border: 0;
    }

    .edit-button i {
        font-size: 40px;
    }
    .edit-button i {
        font-size: 40px;
    }

    .disable-page,
    .disable-page2,
    .inactive,
    .active,
    .ellipsis {
        font-size: 0.9vw;
        width: 90px;
        height: 90px;
        border: 0px;
        margin-right: 15px;
        border-radius: 15px;
    .disable-page,
    .disable-page2,
    .inactive,
    .active,
    .ellipsis {
        font-size: 0.9vw;
        width: 90px;
        height: 90px;
        border: 0px;
        margin-right: 15px;
        border-radius: 15px;

    }
    }

    .items-per-page-dropdown {
        font-size: 1.1vw;
        width: 15%;
        height: 7%;
        border-radius: 20px;
        margin-top: 30px;
        /* height: 20%; */
    }
    .items-per-page-dropdown {
        font-size: 1.1vw;
        width: 15%;
        height: 7%;
        border-radius: 20px;
        margin-top: 30px;
        /* height: 20%; */
    }

    .login form {
        width: 85%;
    }
    .login form {
        width: 85%;
    }

    .opt-btn {
        font-size: 32px;
        /* padding: 30px; */
        /* text-align: left; */
        width: 9vw;
        border-radius: 9px;
    }
    .opt-btn {
        font-size: 32px;
        /* padding: 30px; */
        /* text-align: left; */
        width: 9vw;
        border-radius: 9px;
    }

    .clm-main {
        font-size: 32px;
        text-align: left;
        /* padding: 20px; */
        width: 12vw;
        border-radius: 9px;
        /* margin-left: 45px; */
    .clm-main {
        font-size: 32px;
        text-align: left;
        /* padding: 20px; */
        width: 12vw;
        border-radius: 9px;
        /* margin-left: 45px; */

    }
    }

    .background-space {
        padding: 60px;
    }
    .background-space {
        padding: 60px;
    }


    .new-btn {
        font-size: 32px;
        width: 8vw;
        border-radius: 9px;
        /* margin-left: 45px; */
    }
    .new-btn {
        font-size: 32px;
        width: 8vw;
        border-radius: 9px;
        /* margin-left: 45px; */
    }

    .edit-btn,
    .trash-btn {
        font-size: 1vw;
    }

    .battery_manage {
        font-size: 1vw;
    }

    /* .drop-main {
    /* .drop-main {
        width: 13.5vw;
        font-size: 1vw;
        margin-left: 10.5vw;
        text-align: left;
        /* padding: 10px 10px; */
    /* padding: 5px; */
    /* padding-left: 0px */
    /* } */
    /* padding: 5px; */
    /* padding-left: 0px */
    /* } */

    .title-h {
        font-size: 1.7vw;
    }
    .title-h {
        font-size: 1.7vw;
    }

    .header-title sup {
        font-size: 0.9vw;
    }
    .header-title sup {
        font-size: 0.9vw;
    }

    .time-head {
        font-size: 1.7vw;
    }
    .time-head {
        font-size: 1.7vw;
    }

    .dropdown-toggle::after {
        vertical-align: .100rem;
    }
    .dropdown-toggle::after {
        vertical-align: .100rem;
    }

    .header-admin h4 {
        font-size: 1.6vw;
    }
    .header-admin h4 {
        font-size: 1.6vw;
    }

    .sidebar h4 a {
        font-size: 1.5vw;
    }
    .sidebar h4 a {
        font-size: 1.5vw;
    }

    .sidebar ul li {
        /* padding: 38px 35px; */
    }
    .sidebar ul li {
        /* padding: 38px 35px; */
    }

    .side-title {
        font-size: 1.2vw;
        margin-left: 41px;
    }
    .side-title {
        font-size: 1.2vw;
        margin-left: 41px;
    }

    .side-icn {
        font-size: 1.1vw;
    }
    .side-icn {
        font-size: 1.1vw;
    }

    /* .drop-main {
    /* .drop-main {
        width: 11.5vw;
        font-size: 1.12vw;
        margin-left: 8.5vw;
        text-align: left;
        padding: 10px 10px;
    } */

    .drop-main li {
        cursor: pointer;
        text-align: left;
        padding: 25px;
        padding-left: 3vw;
        border-bottom: 0.5px solid lightgray;
    }
    .drop-main li {
        cursor: pointer;
        text-align: left;
        padding: 25px;
        padding-left: 3vw;
        border-bottom: 0.5px solid lightgray;
    }

    .drop-check {
        height: 50px;
        width: 50px;
    }
    .drop-check {
        height: 50px;
        width: 50px;
    }

    .bell {
        font-size: 1vw;
    }

    .time {
        font-size: 65px;
    }
    .time {
        font-size: 65px;
    }

    .loader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        background-color: #f3f3f3;
        font-family: 'Arial', sans-serif;
        position: relative;
    }
    
    .loader::before {
        content: '';
        width: 60px;
        height: 60px;
        border: 6px solid rgba(243, 243, 243, 0.6); /* Semi-transparent gray */
        border-top: 6px solid #3498db; /* Blue accent */
        border-radius: 50%;
        animation: spin 1.2s ease-in-out infinite;
        box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
    }
    
    .loader p {
        margin-top: 80px;
        color: #3498db;
        font-size: 22px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1.5px;
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    
  

    .options-dropdown li {
        padding: 0.8vw 0.8vw;
        display: flex;
        align-items: center;
        font-size: 50px;
    }
    .options-dropdown li {
        padding: 0.8vw 0.8vw;
        display: flex;
        align-items: center;
        font-size: 50px;
    }

    .custom-link .side-title {
        padding: 0px 0px 0px 0px;
    }

    .custom-link {
        border-radius: 10px;
    }
    .custom-link {
        border-radius: 10px;
    }

    .sidebar ul li a {
        padding-inline-start: 20px;
        padding: 19px 20px;
    }

    .activity {
        font-size: 1.79vw;
        margin: 10px 0px 30px 30px;
    }

    .header-title {}

    header h3 {
        font-size: 2vw;
    }

    .icon-cont {
        font-size: 1.09vw;
    }

   


    aside {
        padding: 20px;
    }
    aside {
        padding: 20px;
    }

    .column td {
        border: 4px solid rgba(120, 154, 201, 0.2);
    }
    .column td {
        border: 4px solid rgba(120, 154, 201, 0.2);
    }

    .column th {
        border: 4px solid rgba(120, 154, 201, 0.2);
    }
    .column th {
        border: 4px solid rgba(120, 154, 201, 0.2);
    }

    .validation-error {
        font-size: 45px;
        text-align: left;
        /* Size for 4000px and larger screens */
    }
    .validation-error {
        font-size: 45px;
        text-align: left;
        /* Size for 4000px and larger screens */
    }

    .d-flex .col {
        margin-left: 0;
        padding: 0px 10px;
    }
    .d-flex .col {
        margin-left: 0;
        padding: 0px 10px;
    }

    .drop-main {
        font-size: 40px;
    }
    .drop-main {
        font-size: 40px;
    }

    .drop-main .checkmark {
        height: 40px;
        width: 40px;
        margin-right: 48px;
    .drop-main .checkmark {
        height: 40px;
        width: 40px;
        margin-right: 48px;

    }
    }

    .drop-main .checkmark:after {
        left: 35%;
        top: 17%;
        width: 11.7px;
        height: 20.7px;
        border: 30px solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .drop-main .checkmark:after {
        left: 35%;
        top: 17%;
        width: 11.7px;
        height: 20.7px;
        border: 30px solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

}

@media screen and (max-width: 3000px) and (min-width: 2000px) {
    .login-img {
        width: 170%;
    }

    .login-page label {
        font-size: 60px;
        margin-bottom: 40px;
    }

    .login-page ::placeholder {
        font-size: 55px;
        z-index: +999;
    }

    .login-page input {
        font-size: 60px;
        border-bottom: 60px solid white;
    }

    .tdflex {
        display: flex;
    }

    .btn-password {
        font-size: 20px;
        margin-top: 28px;
    }

    header {
        padding: 50px;
    }

    .custom-select .react-select__placeholder {
        font-size: 25px;
    }

    .banner form label {
        font-size: 1.2vw;
        margin-bottom: 15px;
    }
    .reg_customer {
        font-size: 1.09vw;
    }
    .dash-pad {
        padding: 15px 15px 0px 15px;
    }

    .header-wrap h2 {
        font-size: 2vw;
        font-weight: 500;
    }

    .login-page .form-check-label {
        font-size: 55px;
    }

    .login-page .forgot-pwd {
        font-size: 55px;
    }

    .login-page .fields {
        margin-bottom: 70px;
    }

    .login-page .login-btn {
        font-size: 55px;
        margin-top: 30px;
        padding: 30px 180px;
    }

    .drop-down {
        font-size: 60px;
        color: #0B2C5F;
        margin: 25px;
    }

    .three-opt {
        display: flex;
        justify-content: space-between;
    }

    .opt-btn {
        font-size: 18px;
        width: 10vw;
        border-radius: 8px;
    }

    .clm-main {
        font-size: 18px;
        width: 13vw;
        border-radius: 8px;
        text-align: left;
    }

    .background-space {
        padding: 70px;
    }

    .new-btn {
        font-size: 18px;
        width: 9vw;
        border-radius: 8px;
    }

    .search-main {
        width: 30vw;
    }

    .save-btn {
        height: 2.5vw;
        width: 8vw;
        font-size: 1.1vw;
        margin-left: 1vw;
    }

    .edit-btn,
    .trash-btn,
    .battery_manage  {
        font-size: 0.8vw;
    }

    .new-btnn-n {
       
        height: 2.6vw;
        width: 4vw;
        color: white;
        font-size: 1vw;
        font-weight: 500;
        border-radius: 4px;
        border: 1px solid #2F6F92;
        background: #2F6F92;
        box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.3);
    }

    .customer_data {
        font-size: 1.4vw;
        margin-top: 1vw;
    }

 


    .i {
        font-size: 22px;
    }


    .btn.active {
        font-size: 25px;
        height: 47px;
        width: 46px;
    }

    .fa {
        font-size: 22px;
    }

    .pg-drp {
        padding: 5px 10px; /* Add padding for better touch experience */
        font-size: 14px; /* Ensure consistent font size */
        border: 1px solid #ccc; /* Subtle border */
        border-radius: 4px; /* Rounded corners */
        background-color: #fff; /* White background */
        cursor: pointer;
        outline: none;
      }

    .side-title {
        font-size: 1.2vw;
        margin-left: 1vw;
    }

 
    .side-icn {
        font-size: 25px;
    }

    .station_data {
        font-size: 1.5vw;
        color: #2F6F92;
        text-align: left;
    }

    .login-button {
        height: 2.5vw;
        font-size: 1.2vw;
    }
    
    .login-button-1 {
        height: 2.5vw;
        font-size: 1.2vw;
    }
    .dele-tab th,
    .dele-tab td {
        font-size: 1.1vw;
    }

    .battrey_manage {
        font-size: 18px;
        margin-right: 10px;
    }

    .button-group .login-bt.active,
    .button-group .login-btt.active,
    .button-group .login-bttt.active {
        background-color: #2f6f92;
        border: 1px solid #2f6f92;
        color: #fff;
        margin-bottom: 9px;
    }

    .button-group .login-bt,
    .button-group .login-btt,
    .button-group .login-bttt {
        background-color: #fff;
        border: 1px solid #2f6f92;
        border-radius: 5px 5px 0 0;
        color: #2f6f92;
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 9px;
        height: 60px;
        outline: none;
        transition: background-color .3s ease, color .3s ease;
    }

    .button-group .login-bt {

        width: 13vw;
        margin-right: 8px;
        font-size: 1.1vw;
    }

    .batt-search {

        border-radius: 4px;
        border: 1px solid lightgray;
        height: 2.5vw;
        width: 17vw;
    }

    .header h3 {
        font-size: 2vw;
    }

    .button-group .login-btt {
        width: 15vw;
        margin-right: 10px;
        font-size: 1.1vw;
    }

    .button-group .login-bttt {
        width: 15vw;
        margin-right: 10px;
        font-size: 1.1vw;
    }

    .login-bt {
        height: 2.8vw;
        width: 9vw;
        font-size: 1.2vw;
    }

    .opt-btn {
        font-size: 1.1vw;
        box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.5);
    }

    .options-dropdown {
        margin-top: 3vw;
    }

    .label_main {
        padding-left: 2vw;
        text-align: left;
    }

    .geekmark {
        height: 1.4vw;
        width: 1.4vw;
    }

    .label_main .geekmark:after {
        left: 8px;
        font-size: 11px;
        bottom: 8px;
        width: 11px;
        height: 19px;
        border: solid white;
        border-width: 0 4px 4px 0;
        transform: rotate(45deg);
    }



    .clm-main {
        font-size: 1.1vw;
        margin-left: 0px;
    }

    .new-btn {
        font-size: 1.1vw;
        border-radius: 5px;
    }

    .options-dropdown li {
        /* padding: 0.8vw 0.8vw; */
        padding: 7px;
        display: flex;
        align-items: center;
        font-size: 1vw;

    }

    .drop-main li {
        font-size: 1.1vw;
        padding-left: 0vw;
        border-bottom: 0.5px solid lightgray;
    }

    .drop-main {
        margin-top: 3vw;
    }



    .dot_opt {
        font-size: 1.1vw;
        padding-left: 0.9vw;
    }

    .popup-list li {
        margin-bottom: 15px;
    }

    .activity {
        font-size: 1.5vw;
        margin-left: 0.6vw;
    }

    .icon-cont {
        font-size: 27px;
    }

    .sidebar h4 a {
        font-size: 28px;
    }
}


@media screen and (max-width: 2000px) and (min-width: 1600px) {
    .login-img {
        width: 150%;
    }

    .login-page label {
        font-size: 50px;
        margin-bottom: 30px;
    }

    .login-page ::placeholder {
        font-size: 45px;
        z-index: +999;
    }

    .login-page input {
        font-size: 50px;
        border-bottom: 50px solid white;
    }

    .tdflex {
        display: flex;
    }

    .btn-password {
        font-size: 17px;
        margin-top: 22px;
    }

    header {
        padding: 40px;
    }

    .custom-select .react-select__placeholder {
        font-size: 20px;
    }

    .banner form label {
        font-size: 1.01vw;
        margin-bottom: 10px;

    }

    .dash-pad {
        padding: 12px 12px 0px 12px;
    }

    .header-wrap h2 {
        font-size: 1.7vw;
        font-weight: 500;
    }

    .login-page .form-check-label {
        font-size: 45px;
    }

    .login-page .forgot-pwd {
        font-size: 45px;
    }

    .login-page .fields {
        margin-bottom: 60px;
    }

    .login-page .login-btn {
        font-size: 45px;
        margin-top: 20px;
        padding: 25px 160px;
    }

    .drop-down {
        font-size: 50px;
        color: #0B2C5F;
        margin: 20px;
    }

    /* add */
    .banner {
        /* padding: 50px 50px; */
    }


    .three-opt {
        display: flex;
        justify-content: space-between;
    }

    .opt-btn {
        font-size: 16px;
        width: 9vw;
        border-radius: 8px;

    }

    .clm-main {
        font-size: 16px;
        width: 12vw;
        border-radius: 8px;
        text-align: left;


    }

    .background-space {
        padding: 60px;
    }


    .new-btn {
        font-size: 16px;
        width: 8vw;
        border-radius: 8px;
        /* margin-left: 45px; */
    }


    .search-main {
        width: 25vw;
    }

    .save-btn {
        height: 2vw;

        width: 7vw;
        font-size: 1.1vw;
    }

    .edit-btn,
    .trash-btn {
        font-size: 15px;
    }


   

   

    .i {
        font-size: 20px;
    }



    .btn.active {
        font-size: 20px;
        height: 37px;
        width: 35px;
    }

    .fa {
        font-size: 13px;
    }
    .pg-drp {
        padding: 5px 10px; /* Add padding for better touch experience */
        font-size: 14px; /* Ensure consistent font size */
        border: 1px solid #ccc; /* Subtle border */
        border-radius: 4px; /* Rounded corners */
        background-color: #fff; /* White background */
        cursor: pointer;
        outline: none;
      }

    .side-title {
        font-size: 1.1vw;
        margin-left: .2vw;
    }

    .side-icn {
        font-size: 18px;
    }

    .popup {}

    .station_data {
        font-size: 1.4vw;
        color: #2F6F92;
        text-align: left;
    }

    .login-button {
        height: 2.2vw;
        font-size: 1vw;
    }
    .login-button-1 {
        height: 2.2vw;
        font-size: 1vw;
    }

    .dele-tab th {
        font-size: 1.1vw;
    }

    .dele-tab td {
        font-size: 1.1vw;
    }

    .battrey_manage {
        border: none;
        background: none;
        color: black;
        font-size: 16px;
        margin-right: 8px;
    }

    .button-group .login-bt.active,
    .button-group .login-btt.active,
    .button-group .login-bttt.active {
        background-color: #2f6f92;
        border: 1px solid #2f6f92;
        color: #fff;
    }

    .button-group .login-bt,
    .button-group .login-btt,
    .button-group .login-bttt {
        background-color: #fff;
        border: 1px solid #2f6f92;
        border-radius: 5px 5px 0 0;
        color: #2f6f92;
        cursor: pointer;
        font-size: 16px;
        height: 42px;
        outline: none;
        transition: background-color .3s ease, color .3s ease;
    }

    .button-group .login-bt {

        width: 251px;
        margin-right: 8px;
        font-size: 1.08vw;
    }

    .login-bt {
        height: 2.5vw;
        width: 8vw;
        font-size: 1.05vw;
    }

    .opt-btn {
        border-radius: 5px;
        border-radius: 5px;
        font-size: 1vw;
        box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.5);

        /* text-align: left; */
    }

    .options-dropdown {
        margin-top: 2.9vw;
    }

    .label_main {
        padding-left: 2vw;
    }

    .clm-main {
        margin-left: 0px;
        border-radius: 5px;
        font-size: 1vw;
        /* text-align: left; */
        font-weight: 500;
    }



    .new-btn {
        font-size: 1vw;
        /* margin-left: 10px; */
        border-radius: 5px;
    }

    .button-group .login-btt {
        width: 266px;
        margin-right: 8px;
        font-size: 1.08vw;
    }

    .drop-main li {
        font-size: 1vw;
        cursor: pointer;
        text-align: left;
        /* padding: 10px 17px; */
        padding-left: 0vw;
        border-bottom: 0.5px solid lightgray;
    }

    .drop-main {
        margin-top: 2.9vw;
    }


    .button-group .login-bttt {
        width: 253px;
        font-size: 1.08vw;
    }

    .dot_opt {
        font-size: .95vw;
        padding-left: 0.7vw;
    }

    .popup-list li {
        margin-bottom: 10px;
    }


    .activity {
        font-size: 1.5vw;
        margin-left: 0.5vw;
    }

    .icon-cont {
        font-size: 18px;
    }

    .sidebar h4 a {
        font-size: 26px;
    }


    .new-btnn {
        /* padding-right: 27px; */
        /* margin-left: 4px; */
        height: 2.6vw;
        width: 10vw;
        color: white;
        font-size: 1.09vw;
        font-weight: 500;
        border-radius: 4px;
        border: 1px solid #2F6F92;
        /* font-family: Geometria; */
        background: #2F6F92;
        box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.3);
    }


}

/* For screens from 1600px down to 1326px */
@media screen and (max-width: 1600px) and (min-width: 1326px) {

    .login-img {
        width: 82%;
    }

    .custom-class {
        padding-right: 7px;
    }

    .d-flex .col {
        margin-left: 0;
        padding: 0px 10px;
    }

    .login-page label {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .login-page ::placeholder {
        font-size: 20px;
        z-index: +999;
    }

    .login-page input {
        font-size: 18px;
        border-bottom: 1px solid white;
    }

    .btn-password {
        font-size: 15px;
    }

    header {
        padding: 15px;
    }

    .login-page .form-check-label {
        font-size: 17px;
    }

    .login-page .forgot-pwd {
        font-size: 17px;
    }

    .login-page .fields {
        margin-bottom: 20px;
    }

    .login-page .login-btn {
        font-size: 20px;
        margin-top: 20px;
        padding: 10px 60px;
    }

    .edit-button {
        background-color: #2F6F92;
        padding: 2px 5px;
        border-radius: 6px;
        color: white;
        font-size: 14px;
        border: 0;
    }

    .login-container input {
        font-size: 14px;
        width: 37vw;
    }

    /* add */
    .banner form label {
        font-size: 14px;
        margin-bottom: 7px;
    }

    form .row {
        margin-bottom: 10px;
    }

    .login-button {
        /* width: 8%; */
        padding-top: 7px;
        padding-bottom: 7px;
        font-weight: 400;
        font-size: 14px;
        border: none;
        /* padding: 5px 20px; */
        cursor: pointer;
        border-radius: 4px;
    }
    .login-button-1 {
        /* width: 8%; */
        padding-top: 7px;
        padding-bottom: 7px;
        font-weight: 400;
        font-size: 14px;
        border: none;
        /* padding: 5px 20px; */
        cursor: pointer;
        border-radius: 4px;
    }
    .dash-pad {
        padding: 12px 12px 0px 12px;
    }

    .select-container .react-select__control {
        min-width: 150px;
    }

    .select-container .react-select__placeholder {
        text-align: left;
        margin-left: 4px;
        margin-right: 4px;
        color: hsl(0, 0%, 50%);
        font-size: 14px;
    }

    .sidebar h4 a {
        font-size: 1.5vw;
    }

    .side-title {
        font-size: 1.1vw;
    }

    .side-icn {
        font-size: 1.1vw;
    }


    .background-space {
        padding: 20px;
    }


    .fa-circle-user:before {
        font-size: 30px;
    }

    .table-container {
        max-width: 100vw;
        /* box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.5); */
        margin-top: -3px;
    }

    .column {
        font-size: 12px;
    }

    .column th {
        font-size: 1.12vw;
    }

    .search-main {
        width: 250px;
        border-radius: 5px;
    }

    .header-admin h4 {
        font-size: 1.6vw;
        margin-top: 6px;
    }


    .sidebar ul li {
        /* padding: 6px 18px; */
    }

    .fa {
        font-size: 10px;
    }

    .disable-page,
    .disable-page2,
    .inactive,
    .active,
    .ellipsis {
        font-size: 13px;
        width: 30px;
        height: 30px;
        border: 0px;
        margin-left: 5px;
    }

    .items-per-page-dropdown {
        font-size: 13px;
        height: 23px;
        width: 34px;
    }

    /* .items-per-page-dropdown {
        height: 23px;
        width: 30px;
    } */

    /* .drop-main {
        width: 13vw;
        text-align: left;
        padding-left: 20px;
        margin-left: 10.5vw;
        font-size: 15px;
        margin: 1vw 149px;

    } */

    /* .drop-main li {
        padding: 14px 10px;
    } */

    .save-btn {
        height: 2.5vw;
        width: 6.5vw;
        font-size: 1vw;
    }

    .time-head {
        font-size: 1.5vw;
    }

    .opt-btn {
        border-radius: 5px;
        border-radius: 5px;
        font-size: 14px;
        box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.5);

        /* text-align: left; */
    }

    .clm-main {
        margin-left: 0px;
        border-radius: 5px;
        font-size: 14px;
        /* text-align: left; */
        font-weight: 500;
    }

    header h2,
    header h3,
    header h4 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .new-btn {
        font-size: 14px;
        /* margin-left: 10px; */
        border-radius: 5px;
    }

    .drop-check {
        /* height: 25px; */
        width: 15px;
        left: 22px;
    }

    /* .bell {
        font-size: 20px;
    } */

    .time {
        font-size: 25px;
    }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

    .activity {
        margin: 2px 0px 10px 10px;
    }


    .new-btnn {
        /* padding-right: 27px; */
        /* margin-left: 4px; */
        height: 2.6vw;
        width: 10vw;
        color: white;
        font-size: 0.95vw;
        font-weight: 500;
        border-radius: 4px;
        border: 1px solid #2F6F92;
        /* font-family: Geometria; */
        background: #2F6F92;
        box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.3);
    }


   

    .i {
        font-size: 13px;
    }

    .pagi-dropdown {
        width: 3.5%;
        font-size: 12px;
    }

    .drop-main .checkmark {
        position: relative;
        height: 15px;
        width: 15px;
        border-radius: 17%;
        border: 2px solid #2F6F92;
        display: inline-block;
        margin-right: 18px;
    }

    .drop-main .drop-check:checked~.checkmark {
        background-color: #2F6F92;
        border: 2px solid #2F6F92;
    }

    .drop-main .drop-check:disabled~.checkmark {
        background-color: #D7D7D7;
        border: 2px solid #D7D7D7;
    }

    .drop-main .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .drop-main .drop-check:checked~.checkmark:after {
        display: block;
    }

    .drop-main .drop-check:disabled~.checkmark:after {
        display: block;
    }

    .drop-main .checkmark:after {
        left: 29%;
        top: 10%;
        width: 5.7px;
        height: 7.7px;
        border: 11px solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    .drop-main li {
        cursor: pointer;
        text-align: left;
        /* padding: 10px 17px; */
        padding-left: 0vw;
        border-bottom: 0.5px solid lightgray;
    }


}

@media screen and (max-width: 1326px) and (min-width: 980px) {
    .login-img {
        width: 50%;
    }

    .login-page label {
        font-size: 17px;
        margin-bottom: 10px;
    }

    .login-page ::placeholder {
        font-size: 16px;
        z-index: +999;
    }

    .login-page input {
        font-size: 16px;
        border-bottom: 1px solid white;
    }


    .column th {
        font-size: 13px;

    }
    .column td {
        font-size: 13px;
    }

    .btn-password {
        font-size: 13px;
    }

    header {
        padding: 8px;
    }

    .login-page .form-check-label {
        font-size: 17px;
    }

    .login-page .forgot-pwd {
        font-size: 17px;
    }

    .login-page .fields {
        margin-bottom: 20px;
    }

    .login-page .login-btn {
        font-size: 16px;
        margin-top: 20px;
        padding: 7px 50px;
    }

    .login-container input {
        font-size: 12px;
    }

    .fa-circle-user:before {
        font-size: 25px;
    }

    .background-space {
        padding: 10px;
    }


    /* add */
    .banner form label {
        font-size: 13px;
        /* margin-bottom: 8px; */
    }

    form .row {
        margin-bottom: 10px;
    }

    .login-button {
        width: 11vw;
        padding-top: 4px;
        padding-bottom: 4px;
        font-weight: 400;
        font-size: 14px;

        border: none;
        /* padding: 5px 20px; */
        border-radius: 4px;
        cursor: pointer;
    }
    
    .login-button-1 {
        width: 11vw;
        padding-top: 4px;
        padding-bottom: 4px;
        font-weight: 400;
        font-size: 14px;

        border: none;
        /* padding: 5px 20px; */
        border-radius: 4px;
        cursor: pointer;
    }
    .popup {
        position: absolute;
        background-color: white;
        border: 1px solid #ccc;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        /* padding: 10px; */
        border-radius: 4px;
    }

    .popup-list li {
        padding: 0px;
        margin-bottom: 10px;
    }

    .dash-pad {
        padding: 10px 10px 0px 10px;
    }

    .select-container .react-select__control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #0B2C5F;
        border-radius: 0;
        box-shadow: none;
        color: #0B2C5F;
        height: 40px;
        min-width: 200px;
        font-size: 20px;
        width: 100%;
    }

    .select-container .react-select__menu {
        text-align: left;
        width: 100%;
        font-size: 20px;
    }

    .select-container .react-select__single-value {
        text-align: left;
        font-size: 20px;
    }

    .activity {
        font-size: 19px;
    }

    .sidebar ul li a {
        padding-inline-start: 4px;

    }

    .icon-cont {
        font-size: 14px;
        width: 2vw;
    }

    .side-title {
        font-size: 14px;

    }

    .side-icn {
        /* margin-right: 10px; */
        font-size: 14px;
    }

    .custom-link .side-title {
        padding: 5px;
    }

    .time {
        font-size: 17px;
    }

 
    .search-main {
        width: 22vw;
    }
    header h2,
    header h3,
    header h4 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 21px;
    }

    .edit-button {
        background-color: #2F6F92;
        padding: 3px 8px;
        border-radius: 6px;
        color: white;
        border: 0;
    }

    .edit-button i {
        font-size: 12px;
    }

    .slider.round {
        height: 1.8vw;
        width: 4.40vw;
        margin-top: 0.4vw;


    }

    .options-dropdown {}

    .clm-main {
        margin-right: 14px;
    }

    .label_main {
        text-align: left;
        padding-left: 2.2vw;
        width: 18vw;

    }


    .new-btnn {
        /* padding-right: 27px; */
        margin-left: 4px;
        height: 2.6vw;
        width: 10vw;
        color: white;
        font-size: 1.09vw;
        font-weight: 500;
        border-radius: 4px;
        border: 1px solid #2F6F92;
        /* font-family: Geometria; */
        background: #2F6F92;
        box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.3);
    }


    .drop-main li {
        padding: 6px;
        cursor: pointer;
        border-bottom: 0.5px solid lightgray;

    }

    .drop-main {
        margin-left: 11.7vw;
        width: 19vw;
    }

    .options-dropdown li {
        font-size: 1.2vw;
    }

    .save-btn {
        margin-left: 0.8vw;
        height: 2.5vw;
        width: 8vw;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 1.5vw;
        width: 1.4vw;
        /* right: -8vw; */
        left: 0.4vw;
        bottom: .2vw;
        background-color: white;
    }



    .button-group .login-bt.active,
    .button-group .login-btt.active,
    .button-group .login-bttt.active {
        background-color: #2f6f92;
        border: 1px solid #2f6f92;
        color: #fff;
    }

    .button-group .login-bt,
    .button-group .login-btt,
    .button-group .login-bttt {
        background-color: #fff;
        border: 1px solid #2f6f92;
        border-radius: 5px 5px 0 0;
        color: #2f6f92;
        cursor: pointer;
        font-size: 16px;
        height: 37px;
        outline: none;
        transition: background-color .3s ease, color .3s ease;
    }

    .button-group .login-bt {

        width: 162px;
        margin-right: 8px;
        font-size: 1.2vw;
    }

    .button-group .login-btt {
        width: 202px;
        margin-right: 8px;
        font-size: 1.2vw;
    }

    .button-group .login-bttt {
        width: 202px;
        width: 185px;
        font-size: 1.2vw;
    }

    .dele-tab th {
        font-size: 1.2vw;
    }

    .dele-tab td {
        font-size: 1.1vw;
    }

    .customer_data {
        margin-right: 80%;
    }

    .qr-model {
        font-size: 1.67vw;
    }


    .tdflex {
        display: flex;
    }

    .login form {
        width: 80%;
    }

}

@media (max-width: 980px) {
    .login-img {
        width: 60%;
    }

    .sidebar h4 a {
        font-size: 2.09vw;
    }

    .side-title {
        font-size: 1.6vw;
        /* margin-left: 27px; */
    }

    .side-icn {
        font-size: 1.6vw;
    }

    .page-main {
        margin-right: 0;
    }

    .serch-div {
        /* flex-direction: column; */
        align-items: flex-start;
    }

    .search-main {
        width: 100%;
        margin-bottom: 10px;
    }

    .opt-btn {
        width: 12vw;
        font-size: 12px;
        margin-right: 10px;

    }
    .clm-main {
        /* width: 15vw; */
        font-size: 12px;

    }

    .login .login-btn {
        margin-top: 20px;
        font-size: 13px;
        width: 30%;

    }

    .login .fields {
        margin-bottom: 10px;
    }

    .new-btn {
        width: 9vw;
        font-size: 12px;
        margin-left: 9px;

    }

    .column {
        font-size: 0.8rem;
        /* Adjust table font size for smaller screens */
    }

    .table-container {
        overflow-x: auto;
    }

    .modal-content {
        width: 90%;
        max-width: 400px;
    }

    .time-head {
        font-size: 17px;
    }

    .btn-opt-head {
        display: flex;
        justify-content: space-between;
    }

    .act-btn {
        display: flex;
    }


    .edit-btn,
    .trash-btn {
        font-size: 1.12vw;

    }

    .disable-page,
    .disable-page2,
    .inactive,
    .active,
    .ellipsis {

        width: 25px;
        height: 25px;
        border: 0;
        font-size: 1.1vw;
    }

    .items-per-page-dropdown {
        height: 23px;
        /* width: 30px; */
        font-size: 14px;
    }

    .items-per-page-dropdown option {
        font-size: 12px;
    }

    .column th {
        font-size: 13px;
    }

    .column td {
        font-size: 12px;
    }

    .sidebar h4 a {
        font-size: 1.9vw;
        margin-left: 4px;
    }

    .side-title {
        font-size: 1.4vw;
    }

    .side-icn {
        font-size: 1.5vw;
    }

    .drop-main li {
        font-size: 1.2vw;
        text-align: left;
        /* padding-left: 49px; */
        border-bottom: 0.5px solid lightgray;
    }

    .drop-main .checkmark:after {
        left: 27%;
        top: 10%;
        width: 5.7px;
        height: 7.7px;
        border: 11px solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    /* .drop-main {
        margin-top: 28px;
        width: 159px;
        margin-left: 144px;
        ;
    } */

    .drop-check {
        height: 14px;
        width: 14px;
        left: 22px;
    }

    .save-btn {
        width: 10vw;
        height: 4vw;
        font-size: 1.5vw;
    }

    .dropdown-menu {
        height: 75px;
    }

    .dropdown-item {
        font-size: 14px;
    }

    header h2,
    header h3,
    header h4 {
        margin-top: 0;
        margin-bottom: 0;
    }


    .i {
        font-size: 11px;
    }

    .pagi-dropdown {
        width: 4.5%;
        font-size: 12px;
    }
}


@media (min-width: 4000px) {
    .ev-maintain img {
        width: 250vh;
        /* Adjust these values as needed */
        height: 200vh;
    }

    .ev-maintain h1 {
        font-size: 4rem;
        /* Larger font size for larger screens */
    }

    .ev-maintain p {
        font-size: 2.5rem;
    }

    .learn-more {
        font-size: 2rem;
        padding: 20px 40px;
    }
}

/* Media Query for 1600px Screen Width */
@media (max-width: 1600px) {
    .ev-maintain img {
        width: 120vh;
        height: 100vh;
    }

    /* .ev-maintain h1 {
        font-size: 2.2rem;
    } */

    .ev-maintain p {
        font-size: 1.1rem;
    }

    .learn-more {
        font-size: 1.2rem;
        /* padding: 12px 24px; */
    }
}

/* Media Query for 980px Screen Width */
@media (max-width: 980px) {
    .ev-maintain img {
        width: 80vh;
        height: 60vh;
    }

    .ev-maintain h1 {
        font-size: 2rem;
    }

    .ev-maintain p {
        font-size: 1rem;
    }

    .learn-more {
        font-size: 1rem;
        padding: 8px 16px;
    }
}
/* General Popup Styles */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Dark overlay for better focus */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 650px; /* Responsive max width */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out; /* Smooth fade-in effect */
}

.popup-content h2 {
    font-size: 1.6rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.popup-content p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.5;
}

.popup-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 25px;
    background-color: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
}

.popup-table th,
.popup-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.popup-table th {
    background-color: #2F6F92;
    color: white;
    font-weight: bold;
}

.popup-table td {
    background-color: #fff;
    color: #333;
}

.popup-button {
    background-color: #2F6F92;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.popup-button:hover {
    background-color: #1f4a6d;
    transform: translateY(-3px); /* Slight button hover effect */
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
}

.popup-label {
    font-size: 0.9rem;
    color: #555;
    margin-left: 10px;
    display: inline-block;
    margin-top: 10px;
    transition: opacity 0.3s ease;
}

.popup-content .popup-button:focus {
    outline: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 600px) {
    .popup-content {
        width: 95%;
    }

    .popup-button {
        font-size: 0.9rem;
        padding: 10px 18px;
    }

    .popup-label {
        font-size: 0.8rem;
    }
}




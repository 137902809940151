@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* toggle switch css */
.switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3.02px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2F6F92;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2F6F92;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* sidebar */
aside {
  position: relative;
  width: 16.75vw;
  background-color: #fff;
  color: #c5c4c4;
  padding: 5px;
  position: fixed;
  top: 4.5vw;
  bottom: 0;
  z-index: 1;
  padding: 7px;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Poppins", sans-serif;

}



.font-style {
  font-family: "Poppins", sans-serif;
}

.banner form label {
  font-size: 17px;
  font-family: "Poppins", sans-serif;
}

.text {
  color: #2F6F92;
  font-weight: 500;
}

.sidebar h4 a {
  color: #0B2C5F;
  text-decoration: none !important;
  color: #2F6F92;
}

.sidebar ul li a {
  color: #2F6F92;
  font-weight: 500;
  padding-inline-start: 10px;

}

.edit-button {
  background-color: #2F6F92;
  padding: 4px 8px;
  border-radius: 6px;
  color: white;
  border: 0;

}


.sidebar ul li {
  /* padding: 10px 15px  ; */
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.login form {
  width: 80%;
}

.i {
  font-size: 13px;
}

.pagi-dropdown {
  width: 3.5%;
  font-size: 12px;
}


.custom-link {
  display: flex;
  align-items: center;
  color: inherit;
  border-radius: 4px;
  text-decoration: none;
  margin-bottom: 5px;
}

.custom-list-item:hover .custom-link {
  background-color: #2F6F92;
  color: white;
  transition: 0.3s;
}

.custom-list-item:hover .custom-link .side-title {
  color: white;
}


.custom-link .side-title {
  padding: 9px;
}

form .row {
  margin-bottom: 10px;
}


.sidebar {
  overflow-y: scroll;
  max-height: 100vh;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-width: 13%;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.menu-item {
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.menu-item:hover {
  background-color: #2F6F92;
  color: white;
}

.menu-item.active {
  background-color: #2F6F92;
  color: white;
}

.menu-item.active .text,
.menu-item.active i {
  color: white;
}

.menu-item.active:hover {
  background-color: #2F6F92;
}


.menu-item.active .submenu {
  display: block;
}


/* .submenu  {
  color: white !important;
  background-color: #0a254a; 
  text-decoration: none;
  display: block;
} */

/* .submenu a:hover {
  background-color: #0a254a; 
} */


/* header */
header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 4.5vw;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.user-icon i {
  color: #2F6F92;
}

.header_logo {
  display: flex;
  column-gap: 0.31vw;
  align-items: center;
  width: 16.71vw;
}

.header_logo img {
  width: 70%;
}

.header_logo h2 {
  color: #0B2C5F;
  /* font-family: Geometria; */
  font-size: 1.65vw;
  font-weight: 700
}

.header-wrap {
  color: #0B2C5F;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 35px; */
  color: #2F6F92;
}

.header-wrap h2 {
  font-size: 1.9vw;
  font-weight: 500;
}

.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3vw;
  color: #2F6F92;
}

.header-title h3 a {
  font-size: 1.9vw;
  font-weight: 400;
}

.header-title sup {
  font-size: 0.93vw;
}

.header-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.7vw;
}

.header-admin h4 {
  font-size: 1.9vw;
  font-weight: 400;
}

.header-admin img {
  padding-bottom: 6px;
}

/* column chooser */
.column td {
  padding: 0.2vw 0.5vw;
  border: 0.25px solid rgba(120, 154, 201, 0.2);
  color: #777;
  /* font-family: Geometria; */
  font-size: 1vw;
  font-weight: 400;
  text-align: left;
}

.column th {
  text-align: left;
  border: 1px solid rgba(120, 154, 201, 0.2);
  padding: 0.55vw 0.55vw;
  color: #4475b7;
  background: rgba(247, 244, 255, 1);
  /* font-family: Geometria; */
  font-size: 1.11vw;
  font-weight: 500;
}

.column {
  width: 100%;
  /* margin: 20px; */
}

/* .save-btn {
  width: 6vw;
  margin-left: 47%;
} */

/* .search-main {
  height: 2.8vw;
  width: 33.61vw;
  border-radius: 4px;
  border: 1px solid #d0dceb;
  background: #fff;
  color: #777;
  font-family: Geometria;
  
  padding-right: 29vw;
} */

.serch-div {
  /* direction: rtl; */
  display: flex;
  justify-content: space-between;
  /* margin: 20px; */
  margin-bottom: 27px;

}

.items-per-page-dropdown {
  border: none;
  background: rgba(247, 244, 255, 1);
  border-radius: 4px;
}



.disable-page {
  background: #2F6F92;
  color: white;
  height: 29px;
  width: 25px;
  border-radius: 4px;
  font-size: 1vw;
}

.disable-page2 {
  background: #2F6F92;
  color: white;
  height: 29px;
  width: 25px;
  border-radius: 4px;
  font-size: 1vw;
}

.active {
  background: #2F6F92;
  color: white;
  height: 26px;
  width: 25px;
  border-radius: 4px;
  font-size: 1vw;
}

.inactive {
  color: black;
  /* background: white; */
  border-radius: 4px;
  border: 0.5px solid white;
}


/* Main dropdown container */
.drop-main {
  list-style-type: none;
  padding-left: 0;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  width: 180px;
  border: 0 solid #c9d7e9;
  margin: 0px;
  background: white;
  margin-left: 11%;
  max-height: 300px;
  overflow-y: auto;
}

/* Individual dropdown items */
.drop-main li {
  padding: 6px;
  cursor: pointer;
  border-bottom: 0.5px solid lightgray;

}

/* Style for the Save button */
.save-btn {
  margin-left: 5vw;
  height: 40px;
  width: 122px;
  border-radius: 4px;
  border: 1px solid #2F6F92;
  font-size: 1.09vw;
  color: white;
  background: #2F6F92;
  font-weight: 500;
}

/* Search input */
.search-main {

  height: 2.8vw;
  width: 33.61vw;
  /* width: 28.31vw; */
  border-radius: 4px;
  border: 1px solid #d0dceb;
  background: #fff;
  color: #777;
  /* font-family: Geometria; */
  font-size: 1.09vw;
  font-weight: 500;
  padding-left: 1.17vw;
}

/* Column Chooser button */

.clm-main {
  border: 1px solid #d0dceb;
  border-radius: 4px;
  height: 2.6vw;
  width: 177px;
  padding-right: 14px;
  /* font-family: Geometria; */
  font-size: 1.09vw;
  color: #2F6F92;

  background: rgba(247, 244, 255, 1);
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.5);


}

.drop-check {
  left: 22px;
  /* top: 0; */
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #2F6F92;
}

.trash-btn {
  border: none;
  background: none;
  color: black;
  font-size: 13px;
}

.edit-btn {
  border: none;
  background: none;
  color: black;
  text-decoration: none;
  padding-left: 6px;
  font-size: 13px;
}

.bt_qr {
  border: none;
  background: none;
  color: black;
  text-decoration: none;
  padding-left: 6px;
  font-size: 13px;
}

.page-main {
  margin-top: 20px;
}

.opt-btn {
  /* Add any specific styles for the Option button */
  margin-right: 20px;
  height: 2.5vw;
  width: 127px;
  padding-right: 30px;
  color: #2F6F92;
  font-size: 1.09vw;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #d0dceb;
  /* font-family: Geometria; */
  background: rgba(247, 244, 255, 1);
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.5);

}

.new-btn {
  padding-right: 27px;
  margin-left: 20px;
  height: 2.6vw;
  width: 104px;
  color: white;
  font-size: 1.09vw;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #2F6F92;
  /* font-family: Geometria; */
  background: #2F6F92;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.3);
}

.filter-btn {
  padding-right: 7px;
  margin-right: 20px;
  height: 2.6vw;
  width: 40px;
  color: rgb(255, 255, 255);
  font-size: 1.09vw;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #2F6F92;
  background: #2F6F92;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.3);
}

.reg_customer {
  padding-right: 9px;
  margin-left: 20px;
  height: 2.6vw;
  /* width: 104px; */
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #2F6F92;
  /* font-family: Geometria; */
  background: #2F6F92;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.3);


}

.serch-div {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

/* .fa-soli {
  padding-right: 18px;
} */
.custom-class {
  padding-right: 7px;
}


.new_client_title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.station-btn {
  color: #4475B7;
  /* font-family: Geometria; */
  column-gap: 0.62vw;
  font-size: 1.093vw;
  font-weight: 600;
  background-color: #fff;
  border-radius: 4px;
  border: 0.5px solid #C9D7E9;
  width: 7.26vw;
  height: 2.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 72vw;
}

.br-line {
  border: 0.5px solid #d0dceb96;
  width: 100%;
  margin-top: 26px;
}

.maindiv {
  margin-top: 50px;
}

.new_client_form {
  display: flex;
  column-gap: 5.03vw;
}

.new_client_part_1 {
  margin-bottom: 1.56vw;
}

.lbl {
  color: #555;
  /* font-family: Geometria; */
  font-size: 1.11vw;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
}

.form-input {
  border-radius: 4px;
  background: #F7F4FF;
  border: none;
  width: 100%;
  /* font-family: Geometria; */
  font-size: 0.93vw;
  font-weight: 400;
  padding: 1.4vw 0.8vw;
  margin-top: 0.78vw;
  height: 44px;
}

.line-frm {
  margin-bottom: 28px;
  color: inherit;
  border: 0.5px solid #F7F4FF;
}

.lgn-save {
  background: #2F6F92;
  color: white;
  height: 2.56vw;
  width: 7.26vw;
  border-radius: 4px;
  border: 1px solid;
  font-size: 1.09vw;
}

.d-save {
  display: flex;
  margin-top: 31px;
  margin-right: 38px;
}

.lgn-reset {
  background: #2F6F92;
  color: white;
  height: 2.56vw;
  width: 7.26vw;
  border-radius: 4px;
  border: 1px solid;
  font-size: 1.09vw;
}

.btn-sr {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
  margin-top: 44px;
}

.lbl-edit {
  display: flex;
  justify-content: flex-start;
  font-size: 1.19vw;
  font-weight: 400;
}

.valid-span {
  color: red;
}

.options-dropdown {
  border-radius: 4px;
  border: 0.5px solid #C9D7E9;
  background: #fff;
  padding: 5px;
  margin: 4px;
  width: 15vw;
  display: block;
  position: absolute;
  list-style: none;
  z-index: 1;
  box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.5);

}

.pg-drp {
  padding: 5px 10px;
  /* Add padding for better touch experience */
  font-size: 14px;
  /* Ensure consistent font size */
  border: 1px solid #ccc;
  /* Subtle border */
  border-radius: 4px;
  /* Rounded corners */
  background-color: #fff;
  /* White background */
  cursor: pointer;
  outline: none;
}

.options-dropdown li {
  /* padding: 0.8vw 0.8vw; */
  padding: 7px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
}

.options-dropdown i {
  color: #2F6F92;
  margin-right: 0.8vw;
}


.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f3f3f3;
  font-family: 'Arial', sans-serif;
  position: relative;
}

.loader::before {
  content: '';
  width: 60px;
  height: 60px;
  border: 6px solid rgba(243, 243, 243, 0.6); /* Semi-transparent gray */
  border-top: 6px solid #3498db; /* Blue accent */
  border-radius: 50%;
  animation: spin 1.2s ease-in-out infinite;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
}

.loader p {
  margin-top: 80px;
  color: #3498db;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
/* .spinner {
  border: 80px solid #f3f3f3;
  border-top: 80px solid #0B2C5F;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 20s linear infinite;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: +99;
} */

.page-1 {
  background-color: #2F6F92;
  color: white;
  border: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.d-flex .col {
  margin-left: 0;
  padding: 0px 10px;
}


.form-control:disabled {
  background-color: white !important;
  opacity: 1;
}

/* 
.options-dropdown {
  border-radius: 4px;
  border: 0.5px solid #C9D7E9;
  background: #fff;
  padding: 1vw 0.5vw !important;
  margin: 4px;
  width: 19vw;
  display: block;
  position: absolute;
  list-style: none;
  z-index: 1;
} */


.options-dropdown i {
  color: #2F6F92;
  margin-right: 0.8vw;
}


.validation-error {
  text-align: left;
}

/* checkbox */
.drop-main {
  padding-left: 6px;
  margin-left: 0.5vw;
  font-size: 15px;
  width: 18vw;
  margin-top: 5px;
  box-shadow: -3px 8px 10px rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.drop-main-1 {
  /* text-align: left; */
  padding-left: 6px;
  margin-left: 9.5vw;
  font-size: 15px;
  width: 18vw;
  margin-top: 5px;
  box-shadow: -3px 8px 10px rgba(0, 0, 0, 0.5);
  overflow: auto;
}

/* Hide the browser's default checkbox */
.drop-main .drop-check {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.drop-main .checkmark {
  position: relative;
  height: 15px;
  width: 15px;
  border-radius: 17%;
  border: 2px solid #2F6F92;
  display: inline-block;
  margin-right: 18px;
}

.drop-main .drop-check:checked~.checkmark {
  background-color: #2F6F92;
  border: 2px solid #2F6F92;
}

.drop-main .drop-check:disabled~.checkmark {
  background-color: #D7D7D7;
  border: 2px solid #D7D7D7;
}

.drop-main .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.drop-main .drop-check:checked~.checkmark:after {
  display: block;
}

.drop-main .drop-check:disabled~.checkmark:after {
  display: block;
}

.drop-main .checkmark:after {
  left: 29%;
  top: 10%;
  width: 5.7px;
  height: 7.7px;
  border: 11px solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}



.custom-list-item {
  position: relative;
}

.custom-link {
  display: flex;
  align-items: center;
  /* padding: 10px; */
  color: #333;
  text-decoration: none;
}

.custom-link:hover {
  background-color: #2F6F92;
  color: white;
}

.icon-cont {
  width: 24px;
}

.side-icn {
  /* margin-right: 10px; */
  font-size: 24px;
}

.side-title {
  margin-left: -4px;
}

.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #2F6F92;
  border-radius: 3px;
  position: relative;
  width: 100%;
  font-weight: 100 !important;
  font-size: 14px;
}

.submenu-item {
  padding: 7px 10px;
}

.submenu-item .custom-link {
  padding: 5px 10px;
  color: white;
  font-weight: 100 !important;
}

.submenu li {
  color: white;
  font-weight: 100 !important;
}




.sidebar ul {
  padding-left: 0;
  margin: 0;
}

/* maintenance */
/* CSS for Maintenancemode Component */
.maineten {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(247, 244, 255, 1);
  /* overflow: hidden; /* Prevents scrolling */
}

.ev-maintain {
  position: relative;
  text-align: center;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  /* Prevents content overflow*/
}

.ev-maintain img {
  width: 100%;
  height: auto;
  /* Maintain aspect ratio */
  object-fit: cover;
  /* Ensures the image covers the container */
}

.ev-maintain h1,
.ev-maintain p {
  position: absolute;
  width: 90%;
  /* Keeps text within 90% of image width */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
  /* Removes default margin */
  padding: 0 10px;
  /* Adds padding to prevent text from touching the image edges */
}

.ev-maintain h1 {
  font-size: 1.9vw;
  color: red;
  top: 74%;
}

.ev-maintain p {
  top: 80%;
  font-size: 1.3vw;
  color: red;
}


.learn-more {
  background-color: #6200ea;
  color: white;
  padding: 5px 12px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  position: absolute;
  top: 90%;
  /* Positioning the button below the text */
  left: 50%;
  transform: translateX(-50%);
}

.learn-more:hover {
  background-color: #3700b3;
}


/* .ev-maintain{
  position: fixed;
  overflow: hidden;
} */

.custom-list-item.active-menu-item,
.custom-list-item.active-menu-item:hover {
  background-color: #2f6f92;
  color: #fff;
  /* Keep text color white */
}

.custom-list-item.active-menu-item a {
  color: #fff;
  /* Ensure links inside the active item stay white */
}

/* Style for the placeholder text in the Select component */
.select-container .react-select__placeholder {
  text-align: left;
  margin-left: 4px;
  margin-right: 4px;
  color: hsl(0, 0%, 50%);
  font-size: 5px;
}

.bool-check {
  justify-content: start;
  display: flex;
}

.col-bool {
  display: flex;

}

.submit_for {
  margin-top: 100px;
  background: white;
  border-radius: 9px;
  height: 45px;
  width: 12vw;
  font-weight: 500;
  font-size: 1.25vw;
  border: none;
  text-align: center;
  margin-left: 24%;

}

.in_forg {
  display: flex;
  background: transparent;
  border: none;
  padding-bottom: 9px;
  width: 133%;
  justify-content: flex-start;
  padding-top: 20px;
}

.email_pass {
  display: flex;
  justify-content: flex-start;
  margin-right: 30vw;
  font-size: 20px;

}

.head_for {
  margin-bottom: 9%
}

.in_forg::placeholder {
  font-size: 1.01vw;
  /* Change this to your desired font size */
  font-weight: 300;
}

.gapbetween {
  width: 2.4vw;

}

.otp-input>div>input {
  border: none;
  width: 3em;
  text-align: center;
  background: transparent;
  border-bottom: 2px solid white;

}

.rest_in {
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  margin-left: 133px;
  margin-top: 12px;
  padding-bottom: 10px;
  width: 100%;
  color: white;
}

rest_in::placeholder {
  font-size: 14px;
}

.lbl_rst {
  padding-right: 23%;
  /* padding-right: 11vw;
  font-weight: 400;
  font-size: 1.25vw; */

}

.lbl_rstt {
  padding-right: 19%;

  /* padding-right: 8.5vw;
  font-weight: 400;
  font-size: 1.25vw; */
}

.rest_inn {
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  margin-left: 133px;
  margin-top: 12px;
  padding-bottom: 10px;
  width: 100%;
  color: white;
}

.potp {
  margin-top: 38px;
}

.otp-input {

  /* padding: 14px; */
  margin-top: 40px;
  display: flex;
  align-items: center;
  margin-left: 17px;
}

.abcde {
  color: white;
}

.error {
  color: red;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.battrey_manage {
  border: none;
  background: none;
  color: black;
  font-size: 13px;
  margin-right: 8px;
}

.bat_type {
  margin-right: 72%;
  /* display: flex; */
  font-weight: 400;
  font-size: 17px;
}

.modal-content {
  margin-top: 120px;
  margin-left: 70px;
  height: 518px;
  width: 603px;
}

.btn-sbs {
  color: white;
  background: #2F6F92;
  height: 36px;
  width: 72px;
  border-radius: 8px;
  text-align: center;
  border: none;
}

.gold-sub {
  color: gray;
  font-size: 15px;
  margin-top: 6px;
  display: block;
  /* width: 35%; */
  padding: .375rem .75rem;
  /* font-size: 1rem; */
  font-weight: 400;
  line-height: 1.5;
  /* color: var(--bs-body-color); */
  /* appearance: none; */
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


.sub_type {
  margin-right: 61%;
  /* display: flex; */
  font-weight: 400;
  font-size: 17px;
}

.bell-btn {
  background-color: #2F6F92;
  /* Blue color */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.battery_manage {
  font-size: 13px;
}

.vld_day {
  margin-top: 27px;
  /* margin-right: 73%; */
  font-size: 16px;
}

.Vld_form {
  height: 38px;
  width: 108px;
  text-align: center;

  margin-top: 27px;
  margin-right: 46%;

  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--bs-body-color);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.vld_line {
  border: 1px solid lightgray;
  margin-top: 21px;
}


.icon-style {

  display: flex;
}

.ere-found {
  font-size: 28px;
  color: red;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.drop-checkk {
  position: relative;
  margin-top: 5px;
  width: 16px;
  height: 16px;
}

.col-c {
  display: flex;
  column-gap: 12px;
}

.alc_batt {
  font-size: 14px;
}

.label_main .geekmark:after {
  left: 6px;
  /* font-size: 10px; */
  bottom: 5px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.label_main {
  display: block;
  position: relative;
  padding-left: 27px;
  cursor: pointer;
}

/* Hide the default checkbox */
.label_main input[type="checkbox"] {
  visibility: hidden;
}

.label_main input:checked~.geekmark {
  background-color: #2f6f92;
}

/* Display checkmark when checked */
.label_main input:checked~.geekmark:after {
  display: block;
}

.veh_sub_cust {
  width: 9vw;
  font-size: 1.09vw;
  background: #2f6f92;
  height: 2.5vw;
  border: none;
  border-radius: 5px;
  color: white;
  margin-top: 8vw;
}

.geekmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #2f6f92;
}

.geekmark:after {
  content: "";
  position: absolute;
  display: none;

}

.area-name-input::placeholder {
  color: hsl(0, 0%, 50%);
}


.close-subs {
  margin-left: 37%;
  color: white;
  background: #2f6f92;
  border-radius: 4px;
  height: 25px;
  border: none;
  width: 25px;
}


.close-batt {
  margin-left: 47%;
  color: white;
  background: #2f6f92;
  border-radius: 4px;
  height: 25px;
  border: none;
  width: 25px;
}

.assign-batteries {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.header-h {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.header-h {
  background-color: #f4f4f4;
  font-weight: bold;
}

.row:last-child {
  border-bottom: none;
}

.cell {
  flex: 1;
  text-align: left;
}

.cell input[type='checkbox'] {
  margin: 0;
}

.dealer-info-table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  color: #2F6F92;
  height: 93px;
  border: 1px solid rgba(120, 154, 201, 0.2);
  background-color: rgba(247, 244, 255, 1);
}

.dele-tab th {
  border-inline: 1px solid rgba(120, 154, 201, 0.2);
  font-weight: 500;

}

.dele-tab td {
  border-inline: 1px solid rgba(120, 154, 201, 0.2);
  background-color: white;
  font-weight: 400;

}

.Ass_new_batt {
  display: flex;
  justify-content: space-between;
}

.rdbtn {
  /* margin-right: 14%; */
  display: flex;
  color: #2f6f92;
  font-size: 16px;
  font-weight: 500;
  gap: 21px;

}

.subsub {
  font-size: 18px;
  color: #4a4a4a;
  /* Darker gray for better readability */
  font-weight: 400;
  /* Slightly bolder for better visibility */
  text-align: left;
  /* 'start' replaced with 'left' for broader compatibility */
  align-items: flex-start;
  /* Ensuring proper alignment */
  display: flex;
  line-height: 1.6;
  /* Improved spacing for readability */
  margin-bottom: 10px;
  /* Space between items */
}

.subsub-1 {
  font-size: 18px;
  color: #4a4a4a;
  /* Darker gray for better readability */
  font-weight: 400;
  /* Slightly bolder for better visibility */
  text-align: left;
  /* 'start' replaced with 'left' for broader compatibility */
  align-items: flex-start;
  /* Ensuring proper alignment */
  display: flex;
  line-height: 1.6;
  /* Improved spacing for readability */
  margin-bottom: 10px;
  /* Space between items */
}

.strsub {
  margin-right: 10px;
  /* Slightly reduced for balance */
  font-weight: 600;
  /* Emphasizing the label */
  color: #333;
  /* Darker shade for better contrast */
}

.strsub-1 {
  margin-right: 10px;
  /* Slightly reduced for balance */
  font-weight: 600;
  /* Emphasizing the label */
  color: #333;
  /* Darker shade for better contrast */
}

.subsub-1:last-child {
  margin-bottom: 0;
  /* Removes bottom margin for the last item */
}



.table-container {
  max-width: 100%;
  margin-top: -3px;
}


.popup {
  position: absolute;
  /* Make sure it's positioned above the td */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  /* Ensure it appears above other elements */
  padding: 10px;
  border-radius: 4px;
  /* Add more styles as needed */
}

.three-dots {
  border: none;
  /* margin-top: 20px; */
  background: white;
  /* padding-top: 28px; */
  /* border-right: none; */
}

.popup-list {
  list-style-type: none;
  /* Remove default list bullets */
  padding: 0;
  margin: 0;
}

.popup-list li {
  margin-bottom: 10px;
  /* Space between list items */
}

.popup-list li:last-child {
  margin-bottom: 0;
  /* Remove space after the last item */
}

.dot_opt {
  padding-left: 8px;
}

.login-bt {
  width: 140px;
  background-color: #2F6F92;
  color: white;
  border-radius: 4px;
  /* margin-right: 20px; */
  border: none;
  height: 36px;
  font-size: 14px;
  margin-bottom: 4px;
}


.search-divv {
  display: flex;
  justify-content: end;
}

.batt-search {
  border-radius: 4px;
  border: 1px solid lightgray;
  height: 39px;
}
.search-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 8px; /* Add spacing below the search container */
}

.new-veh {
  /* height: 39px;
  width: 141px; */
  border: none;
  background: white;
}

.login-button {
  padding-block: 20px;
  width: 13%;
  padding-top: 9px;
  padding-bottom: 9px;
  font-weight: 400;
  font-size: 14px;
  border: none;
  /* padding: 5px 20px; */
  cursor: pointer;
  border-radius: 4px;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 7px;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.5);
}

.login-button-1 {
  padding-block: 20px;
  width: 13%;
  padding-top: 9px;
  padding-bottom: 9px;
  font-weight: 400;
  font-size: 12px !important;
  border: none;
  /* padding: 5px 20px; */
  cursor: pointer;
  border-radius: 4px;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 7px;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.5);
}

/* .button-group .login-bt.active {




  background-color: #2f6f92;
  border: 1px solid #2f6f92;
  color: #fff;
  width: 169px;
}

.button-group .login-bt {
  background-color: #fff;
  border: 1px solid #2f6f92;
  border-radius: 5px 5px 0 0;
  color: #2f6f92;
  cursor: pointer;
  font-size: 16px;
  height: 37px;
  outline: none;
  transition: background-color .3s ease, color .3s ease;
  width: 169px;
}



.button-group .login-btt {
  background-color: #fff;
  border: 1px solid #2f6f92;
  border-radius: 5px 5px 0 0;
  color: #2f6f92;
  cursor: pointer;
  font-size: 16px;
  height: 37px;
  outline: none;
  transition: background-color .3s ease, color .3s ease;
  width: 213px;
  margin-right: 8px;
}

.button-group .login-btt.active {
  background-color: #2f6f92;
  border: 1px solid #2f6f92;
  color: #fff;
  width: 213px;
}

.button-group .login-bttt {
  background-color: #fff;
  border: 1px solid #2f6f92;
  border-radius: 5px 5px 0 0;
  color: #2f6f92;
  cursor: pointer;
  font-size: 16px;
  height: 37px;
  outline: none;
  transition: background-color .3s ease, color .3s ease;
  width: 190px;
}



.button-group .login-bttt.active {
  background-color: #2f6f92;
  border: 1px solid #2f6f92;
  color: #fff;
  width: 190px;
} */


.button-group .login-bt.active,
.button-group .login-btt.active,
.button-group .login-bttt.active {
  background-color: #2f6f92;
  border: 1px solid #2f6f92;
  color: #fff;
}

.button-group .login-bt,
.button-group .login-btt,
.button-group .login-bttt {
  background-color: #fff;
  border: 1px solid #2f6f92;
  border-radius: 5px 5px 0 0;
  color: #2f6f92;
  cursor: pointer;
  font-size: 16px;
  height: 37px;
  outline: none;
  transition: background-color .3s ease, color .3s ease;
}

.button-group .login-bt {
  width: 169px;
}

.button-group .login-btt {
  width: 213px;
  margin-right: 8px;
}

.button-group .login-bttt {
  width: 190px;
}


.button-md-cl {
  margin-left: 37%;
  color: white;
  background: #2f6f92;
  font-size: 15px;
  border: none;
  border-radius: 2px;
}

.button-md-cll {
  margin-left: 53%;
  color: white;
  background: #2f6f92;
  font-size: 15px;
  border: none;
  border-radius: 2px;
}

.veh_sub_head {
  font-size: 18px;
  display: flex;
  /* margin-left: 0px; */
  color: darkslategray;
}


.customer_data {
  font-size: 24px;
  color: rgb(47, 111, 146);
  padding: 10px 0;
  line-height: 1.4;
  text-align: left !important;
}

.customer_dataa {
  margin-top: 20px;
  border: 1px solid lightgray;
  padding: 21px;
  width: 100%;

}

.station_data {
  font-size: 20px;
  color: #2F6F92;
  text-align: left;
}

.login-button-cust {
  background-color: #2F6F92;
  color: white;
  border: none;
  /* padding: 5px 20px; */
  border-radius: 4px;
  cursor: pointer;
}

.login-bt-cjk {
  display: flow;
  margin-left: 80px;
  width: 140px;
  background-color: #2F6F92;
  color: white;
  border-radius: 4px;
  /* margin-right: 20px; */
  border: none;
  height: 36px;
  font-size: 14px;

}

.sure_p {
  font-size: 2vw;
  margin-top: 1vw;
}


.sure_p_s {
  font-size: 0.99vw;
}



.small-popup {
  /* height: 9vw; */
  width: 26vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 2vw;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: none;
}

.small-popup.show {
  display: block;
}

.small-popup-content {
  text-align: center;
}

.small-popup-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 2vw;
}

.new-btnn {
  /* padding-right: 27px; */
  margin-left: 20px;
  height: 2.6vw;
  width: 104px;
  color: white;
  font-size: 1vw;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #2F6F92;
  /* font-family: Geometria; */
  background: #2F6F92;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.3);
}

.btn-btn-danger {

  background-color: indianred;
  color: white;
  border: none;
  border-radius: 3px;
  height: 2vw;
  width: 6vw;
}

.btn-btn-secondary {

  background-color: #2F6F92;
  color: white;
  border: none;
  border-radius: 3px;
  height: 2vw;
  width: 6vw;
}

.react-datepicker-wrapper {
  width: 100%;

}

.react-datepicker-wrapper::placeholder {
  font-weight: 300;
  font-size: 17px;
  padding-left: 10px;
}

/* Style for the input field */
/* Styling the DatePicker input field */
.custom-datepicker {
  font-size: 15px;
  background-color: transparent;
  border: 1px solid lightgray !important;
  border-bottom: 1px solid #0B2C5F;
  color: #0B2C5F;
  border-radius: 6px;

  height: 2.5vw;
  /* Apply height */
  /* width: 100%; */
}

.custom-datepicker input::placeholder {
  color: #0B2C5F;
  /* Placeholder color */
  opacity: 1;
  /* Ensures opacity is not too light */
  font-size: 1rem;
  font-weight: 800;
  /* Optional font size */
}

/* Styling the input text to ensure consistency with placeholder */
.custom-datepicker input {
  color: #0B2C5F;

  /* Input text color */
}

/* Ensure the placeholder remains styled properly when typing */
.custom-datepicker input:not(:placeholder-shown) {
  color: #0B2C5F;
}

.Reason_pop {
  font-size: 1.5vw;
}

.unasign_res {
  height: 3vw;
  width: 22vw;
}

.conm_btn {
  height: 2.2vw;
  width: 102px;
  color: white;
  font-size: 1.0vw;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #2F6F92;
  /* font-family: Geometria; */
  background: #2F6F92;
}

.confirm_res {
  margin-left: 60px;
  display: flex;
  gap: 16px;
  margin-top: 10px;
}

.new-btnn-n {
  /* padding-right: 27px; */
  /* padding-right: 27px; */
  /* margin-left: 20px; */
  height: 2.6vw;
  width: 137px;
  color: white;
  font-size: 1vw;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #2F6F92;
  /* font-family: Geometria; */
  background: #2F6F92;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.3);
}

.coupen_ctr {
  width: 39vw;
  border-radius: 0px;
  border-bottom: 1px solid;
  border-top: none;
  border-left: none;
  border-right: none;
}

.cpn_cont {
  border-radius: 0px;
  border-bottom: 1px solid;
  border-top: none;
  border-left: none;
  border-right: none;
}

.input_check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.input_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 10px;
  width: 10px !important;
}

/* Create a custom checkbox */
.input_check .checkb {
  position: absolute;
  top: -5px;
  left: 45%;
  height: 19px;
  width: 19px;
  border-radius: 100%;
  border: 2px solid #d7d7d7;
}

.input_check input:checked+.checkb {
  background-color: #2F6F92 !important;
  border: 2px solid #2F6F92 !important;
}

.input_check input:disabled+.checkb {
  background-color: #d7d7d7;
  border: 2px solid #d7d7d7;
}

.input_check .checkb:after {
  content: "";
  position: absolute;
  display: none;
}

.input_check input:checked+.checkb::after {
  display: block;
}

.input_check input:disabled+.checkb::after {
  display: block;
}


.input_check .checkb:after {
  left: 37%;
  top: 18%;
  width: 4.7px;
  height: 8.7px;
  border: 4px solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Dashboard CSS */
.dashboard-container {
  padding: 20px;
  min-height: 100vh;
}

.dashboard-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.dashboard-card {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-title {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #4a4a4a;
}

p {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
}

b {
  font-weight: bold;
  color: #222;
}

/* Ensure that the select element has some padding to make space for the icon */
.form-control.ps-5 {
  padding-right: 2.5rem;
  /* Adjust the padding to make space for the icon */
}

/* Style for the dropdown icon */
.fa-chevron-down {
  margin-top: 16px;
  font-size: 1.25rem;
  /* Adjust the icon size */
  color: #6c757d;
  /* Adjust the color of the icon */
  pointer-events: none;
  /* Prevent interaction with the icon */
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.custom-thead {
  background: #eeefff;
  border: 1px solid #789ac933;
  color: #4475b7 !important;
  font-size: 1.11vw;
  font-weight: 500;
  padding: .55vw;
  text-align: left;
}

.custom-th {
  padding: 12px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #4475b7 !important;
}

.custom-td {
  padding: 12px;
  text-align: left;
  font-size: 14px;
  color: #6B7280;
}

.custom-tr {
  border-top: 1px solid #E5E7EB;
}

.custom-tr:hover {
  background-color: #F3F4F6;
  transition: background-color 0.3s ease;
}

.total-data-count {
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #4B5563;
}

/* For small screens, make the table responsive */
@media (max-width: 768px) {

  .custom-th,
  .custom-td {
    font-size: 12px;
    padding: 8px;
  }

  .custom-table {
    font-size: 12px;
  }
}

.bg-vrt {
  background-color: #3F6F92;
  border: none;
  border-radius: 3px;
  color: white;
  padding: 2px 10px;
}

.data-info {
  margin: 10px 0px;
  font-size: 14px;
  text-align: left;
  color: #555;
}

/* Modal Styles */
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  margin-top: 30px;
}

.custom-modal.show {
  display: flex;
}

.custom-modal-dialog {
  background: #fff;
  border-radius: 6px;
  max-width: 900px;
  width: 80%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-modal-header {
  padding: 12px;
  background: #f8f9fa;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal-title {
  margin: 0;
  font-size: 14px;
  color: #2f6f92;
}

.close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.custom-modal-body {
  padding: 12px;
}

.custom-label {
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
}

.form-select,
.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 12px;
  font-size: 12px;
}

.payment-details {
  margin-top: 12px;
}

.info-text {
  font-size: 12px;
  color: #666;
}

.custom-modal-footer {
  padding: 12px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.primary-button {
  background-color: #2f6f92;
  color: #fff;
  padding: 8px 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.primary-button:hover {
  background-color: #246078;
}

.secondary-button {
  background-color: #ddd;
  color: #333;
  padding: 8px 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.secondary-button:hover {
  background-color: #bbb;
}

.subscription-details {
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  max-width: 350px;
  margin: 12px auto;
}

.details-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}

.details-item strong {
  color: #2F6F92;
}

.details-item span {
  font-weight: bold;
  color: #333;
}

.subscription-details p {
  margin: 0;
  padding: 0;
}

.custom-modal-1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  margin-top: 30px;
}

.custom-modal.show-1 {
  display: flex;
}

.custom-modal-dialog-1 {
  background: #fff;
  border-radius: 6px;
  max-width: 500px;
  width: 80%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-modal-header-1 {
  padding: 12px;
  background: #f8f9fa;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal-title-1 {
  margin: 0;
  font-size: 14px;
  color: #2f6f92;
}

.close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.custom-modal-body-1 {
  padding: 12px;
}

.custom-label {
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
}

.form-select,
.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 12px;
  font-size: 12px;
}

.payment-details {
  margin-top: 12px;
}

.info-text {
  font-size: 12px;
  color: #666;
}

.custom-modal-footer {
  padding: 12px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.error-text {
  color: red;
}

.remark {
  color: #2F6F92;
}

.pagination-btn {
  padding: 6px 12px;
  margin: 0 3px;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  color: #4B5563;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination-btn:disabled {
  background-color: #e5e7eb;
  cursor: not-allowed;
}

.pagination-btn.active {
  background-color: #2F6F92;
  color: white;
  font-weight: bold;
  width: auto;
  height: auto;
  border: 2px solid #1D4F76;
  /* Adds a border to the active page */
}

.pagination-btn:hover:not(.active):not(:disabled) {
  background-color: #d1d5db;
  /* Changes color on hover for non-active pages */
}

.paginationc {
  display: flex;
  justify-content: center;
}

.report-pagination-main-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 10px;
}

.cstm-line:focus {
  outline: none !important;
  box-shadow: none !important;
}

.typeimg {
  padding: 10px;
  width: 100px;
  height: 100px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-size: cover !important;
}
.chevron-icon {
  margin-left: 28px; /* Adjust as needed */
}

.loader-1 {
  display: flex;
  margin: auto;
  width: 50px;
  aspect-ratio: 1;
  --b: 8px; 
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #2F6F92) content-box;
  mask: 
    repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
    radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
  mask-composite: intersect;
  -webkit-mask: 
    repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
    radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
  -webkit-mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}

@keyframes l4 {
  to {
    transform: rotate(1turn);
  }
}
.error-text-show{
  margin-bottom: 0 !important;
}